import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { LoginService } from "../../services/login.service";
import { finalize } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
  selector: "app-scadaweb",
  templateUrl: "./scadaweb.component.html",
  styleUrls: ["./scadaweb.component.scss"],
})
export class ScadawebComponent implements OnInit {

  safeUrl: SafeResourceUrl;

  loading: boolean;

  constructor(private sanitizer: DomSanitizer,
    private router: Router,
    private bfcConfigurationService: BfcConfigurationService,
    private credentialService: LoginService) { }

  ngOnInit(): void {
    this.createIframe();
  }

  private createIframe() {
    this.loading = true;

    this.credentialService.login().pipe(
      finalize(() => this.loading = false),
    ).subscribe(
      () => {
        const url = this.bfcConfigurationService.configuration.scadawebUrl;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      },
      () => {
        this.router.navigate(["/loginFailed"]);
      },
    );
  }
}
