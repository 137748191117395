import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private bfcAuthenticationService: BfcAuthenticationService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const allowed = (route && route.data && route.data.roles ? route.data.roles : [])
      .every(role => this.bfcAuthenticationService.hasRealmRole(role));

    if (!allowed) {
      this.router.navigate(["unauthorized"]);
    }
    return allowed;
  }
}
