import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { translations } from "./config/translations/translations";
import { AuthGuard } from "./core/auth.guard";
import { ScadawebComponent } from "./components/scadaweb/scadaweb.component";
import { LoginErrorComponent } from "./components/login-error/login-error.component";

const routes: Routes = [
  /** Unauthorized route on top to prevent conflict with :route */
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "",
    component: ScadawebComponent,
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_SCADAWEB"] },
  },
  {
    path: "loginFailed",
    component: LoginErrorComponent,
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_SCADAWEB"] },
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
