<div class="login-failed columns">
    <div class="rows">
        <div class="logo-container">
            <bfe-icon name="bfc-icon-danger"></bfe-icon>
        </div>
        <div class="text-container header-font">
            {{ 'SCADAWEB.ERRORS.LOGIN_FAILED.HEADLINE' | bfcTranslate }}
        </div>
    </div>
    <div class="content">
        <div class="text-container">
            <div>
                {{ 'SCADAWEB.ERRORS.LOGIN_FAILED.CONTENT' | bfcTranslate }}
            </div>
        </div>
    </div>
</div>
