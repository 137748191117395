export const DE_TRANSLATIONS = {
  SERVICE_NAME: "Scadaweb",
  SCADAWEB: {
    NAVIGATION: {
      PARENT: "Scadaweb",
    },
    ERRORS: {
      LOGIN_FAILED: {
        HEADLINE: "Login fehlgeschlagen",
        CONTENT: "Zugriff auf ScadaWeb aktuell nicht möglich",
      },
    },
  },
  BACK_BUTTON: "zurück zum Kundencenter",
  UNAUTHORIZED: "Sie sind nicht autorisiert für die Applikation.",
};
