import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoginService {

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService) {}

  login(): Observable<void> {
    const loginUrl: string = this.bfcConfigurationService.configuration.apiUrl + "/login";

    return this.httpClient.post<void>(loginUrl, {}, { withCredentials:true });
  }
}
